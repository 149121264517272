.b-chip {
  background: #D8D8D8;
  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  height: 24px;
  display: inline-flex;
  align-items: center;
  color: #636463;

  &--outlined {
    background: transparent;
    color: #636463;
    padding: 6px 8px;
    gap: 4px;
    border: 1px solid #ACACAC;
    border-radius: 56px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
  }

  &--success {
    background: #5BCD71;
    color: #FFF;
  }

  &--danger {
    background: #DA616C;
    color: #FFF;
  }

  &--alert {
    background: #FFB84E;
    color: #1C2246;
  }

  &--primary {
    background: #4F8BFF;
    color: #FFF;
  }
}
