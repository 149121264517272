.dashboard {
  padding: 16px;
  &__card-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 0;
    gap: 16px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
}
.list-view {
  padding: 16px;

  &__card-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 0;
    gap: 16px;
  }
  &__pagination {
    display: flex;
    padding: 16px 0;
    justify-content: right;
  }
}
