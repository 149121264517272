.b-button {
  border-radius: 100px;
  padding: 0 24px;
  border: none;
  outline: none;
  background: linear-gradient(86.19deg, #ED4A3F 19.16%, #F99335 78.75%);
  box-sizing: border-box;
  display: flex;
  height: 40px;
  align-items: center;
  color: #FFF;
  cursor: pointer;
  position: relative;

  &--small {
    padding: 0 12px;
    height: 24px;

    .b-button__text {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &--disabled {
    background: #D8D8D8;
    color: #ACACAC;
    cursor: initial;
  }

  &--new-design {
    background: #FEF0EB;
    border: none;
    color: #ED4A3F;
    align-content: center;
    justify-content: center;

    .b-button__text {
      flex: none;
    }

    .b-button--start-icon {
      margin-right: -10px;
    }
  }

  &--outlined {
    background: #FFFFFF;
    border: 1px solid #636463;
    color: #636463;

    &.b-button--disabled {
      border-color: #D8D8D8;
      background: #D8D8D8;
      color: #ACACAC;
      cursor: initial;
    }
  }

  &--text {
    background: transparent;
    border-color: transparent;
    color: #636463;

    &.b-button--disabled {
      color: #D8D8D8;
    }
  }

  &--start-icon {
    .b-button__text {
      margin-left: 4px;
    }
  }

  &--end-icon {
  }

  &--icon {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  &__text {
    flex: 1;
    color: inherit;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    &--hidden {
      color: transparent !important;
    }

    + .b-button--end-icon {
      margin-left: 4px;
    }
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
