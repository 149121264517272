.email-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1C2246;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #636463;
  }

  &__form {
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  &__btn {
    width: 100%;
  }

  &__link {
    font-size: 14px;
    line-height: 20px;
    color: #1C2246;
    cursor: pointer;
  }

  &__red-link {
    font-size: 12px;
    line-height: 16px;
    color: #ED4A3F;
    cursor: pointer;
  }

  &__caption {
    font-size: 12px;
    line-height: 16px;
    color: #636463;

    &--time {
      color: #ED4A3F;
    }
  }
}
