.toolbar-search {
  &__button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #c0c0c0;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin-left: 0;
    border-radius: 4px;
  }

}
