.technics {
  height: 100%;
  width: 296px;
  display: flex;
  flex-direction: column;
  &__list {
    flex: 1;
  }
  &__filters {
    background: #F4F4F4;
    padding: 16px;
  }
  &__pagination {
    display: flex;
    justify-content: center;
    padding: 16px;
  }

  &__chips {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    gap: 8px;
    margin-bottom: 16px;
  }
  &__chip {
    flex: 0 1 calc(50% - 4px);
    justify-content: center;
  }
}
.geo-zones {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 296px;

  &__filters {
    flex: 0;
    background: #F4F4F4;
    padding: 16px;
  }
  &__list {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
  &__pagination {
    flex: 0;
    display: flex;
    justify-content: center;
    padding: 16px;
  }
}
.tech-list-item {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 4px;
  border-bottom: 1px solid #D8D8D8;

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 4px;
  }
  &__checkbox {
    flex: 1;
  }
  &__time {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    text-decoration-line: underline;
    color: #636463;
  }
  &__subtitle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 32px;
    gap: 10px;

    font-size: 14px;
    line-height: 20px;
    color: #636463;
  }
}
.geo-zones-list {
  &__row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    padding: 12px 16px;
    gap: 12px;
    border-bottom: 1px solid #D8D8D8;
  }
}