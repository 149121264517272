.b-toolbar {
  background-color: #FFF;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;

  &__controls {
    display: flex;
    align-items: center;
    margin-left: -16px;


    &--right {
      display: flex;
      margin-left: auto;
    }

    &--bottom {
      display: flex;
      margin-top: 24px;
    }
  }

  &__control {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-left: 16px;
  }

  &__filter {
    &--clear {
      border: 1px solid #ACACAC;
    }

    &--active {
      border: 1px solid #ED4A3F;

      &::after {
        content: '';
        background-color: #ED4A3F;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    transition: all 0.3s ease-in-out;
  }
}
