.journal-detail {
  //height: 100vh;
  display: flex;
  flex-direction: column;
  &__header {
    position: sticky;
    top: 0;
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 24px;
    gap: 16px;
    background: #F5F6F8;
    border-bottom: 1px solid #D8D8D8;
  }
  &__btn {
    border: none;
    background: none;
  }
  &__divider {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(216, 216, 216, 0.5);
  }
  &__card-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
  }
  &__content {
    flex-grow: 1;
    padding: 16px;
  }

  h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
