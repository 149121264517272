.phone-input {
  height: 40px;
  padding: 0 12px;
  //width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #1C2246;
  font-weight: 400;

  input {
    border: none;
    &:focus-visible {
      outline: none;
    }
  }
}