.b-menu {
  display: flex;
  flex-direction: column;
  height: 100%;

  &--dark {
    .b-menu__link {
      color: #FFF;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1; // This makes the list take up all available space
  }

  &__item {
    height: 56px;
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 4px;
      height: 100%;
      background: linear-gradient(86.19deg, #ED4A3F 19.16%, #F99335 78.75%);
      border-radius: 0 4px 4px 0;
      display: none;
    }

    &:hover,
    &--active {
      &::before {
        display: block;
      }
    }

    &--active {
      .b-menu__text {
        font-weight: 600;
      }
    }
  }

  &__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: inherit;
    padding: 16px 8px;
    box-sizing: border-box;
    cursor: pointer;
    color: #1C2246;
  }

  &__icon {
    margin-right: 4px;
    color: inherit;
    display: inline-flex;
    align-items: center;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: inherit;
    font-weight: 400;
    display: inline-block;
    margin-top: 2px;
  }

  &__badge {
    width: 20px;
    height: 20px;
    background: #DA616C;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    padding: 1px;
    font-size: 12px;
    line-height: 16px;
    margin-left: auto;
  }

  &__footer {
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 0;
    bottom: 30px;
  }
}
