.b-header {
  height: 64px;
  background-color: #FFF;
  border-bottom: 0.5px solid #D8D8D8;
  box-sizing: border-box;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  //background-image: url("../../../../assets/images/ny/main-ny-background.png");
  //background-repeat: no-repeat;
  //background-size: inherit;
  //background-position: center;

  //&:hover {
  //  background-position-y: 50%;
  //}

  @media only screen and (max-width: 1165px) {
    width: 100%; /* Масштабировать ширину на мобильных устройствах */
    height: auto;
    background-size: contain;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1C2246;
    margin: 0;
    text-shadow: 1px 1px 2px rgba(229, 229, 229, 0.8);
  }

  &__right {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    color: #1C2246;
  }

  &__profile {
    display: flex;
    align-items: center;

    > p {
      font-size: 16px;
      line-height: 24px;
      color: inherit;
      margin: 0 0 0 4px;
      text-shadow: 1px 1px 2px rgba(229, 229, 229, 0.8);
    }
  }

  &__button {
    margin-left: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    color: inherit;
    height: 32px;
    width: 32px;

    > div {
      flex: 1;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
