.b-pagination {
  display: flex;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    //width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: transparent;
    font-size: 14px;
    line-height: 20px;
    color: #1C2246;

    &--active {
      background: linear-gradient(86.19deg, #ED4A3F 19.16%, #F99335 78.75%);
      border-radius: 4px;
      cursor: initial;
      color: #fff;
    }

    &--disabled {
      opacity: 0.6;
      cursor: initial;
    }

    &--prev {
      margin-right: 24px;
    }

    &--next {
      margin-left: 24px;
    }
  }
}
