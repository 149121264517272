.b-field {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #1C2246;
  font-weight: 400;

  &--disabled {
    .b-field__label {
      color: #ACACAC;
    }

    .b-field__content {
      background-color: #EEE;
      pointer-events: none;
    }
  }

  &--error {
    .b-field__helper-text {
      color: #DA616C;
    }

    .b-field__content {
      border-color: #DA616C;
    }
  }

  &__label {
    font-size: 12px;
    line-height: 16px;
    color: #636463;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 4px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;

    &--link {
      font-size: 14px;
      line-height: 20px;
      color: #1C2246;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__content {
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    //display: flex;
    width: 100%;
    box-sizing: border-box;
    transition: border 0.1s ease-in-out;
    position: relative;

    &:hover {
      border: 1px solid #1C2246;
    }
  }

  &__append-icon {
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background: none;
    transition: all 200ms ease-in-out;

    &:active {
      transform: scale(0.8);
    }

    &:hover {
      background: #F4F4F4;
      opacity: 1;
    }
  }

  &__helper-text {
    font-size: 12px;
    line-height: 16px;
    color: #000;
    display: block;
    margin-top: 4px;
  }
}
