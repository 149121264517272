.m-document-detail {
  &__grid {
    padding: 16px;
    background: #F5F6F8;
    border-bottom: 1px solid #D8D8D8;
  }
  &__content {
    padding: 0 16px;
  }
  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
    border: 0.5px solid #D8D8D8;
    border-radius: 4px;
  }
  &__titles {
    margin: 0 8px;
  }
  &__text {
    display: inline-block;
  }
  &__labels {
    display: inline-block;
    width: 200px;
    color: #636463;
  }
  &__file {
    width: 303px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    color: #1B1B1B;
    cursor: pointer;
    transition: all 120ms ease-in-out;
    &:hover {
      box-shadow: 2px 0 4px rgba(0, 0, 0, 0.08);
    }
  }
  &__file-title {
    font-size: 14px;
    line-height: 20px;
  }
  &__file-size {
    font-size: 12px;
    line-height: 16px;
    color: #ACACAC;
  }
}
