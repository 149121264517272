.dispatch-calendar {
  width: 100%;

  &__header {
    display: flex;
    margin-bottom: 20px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__controls {
    flex-shrink: 0;
    flex-grow: 0;
  }

  &__control {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    margin-left: 4px;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }

  &__content {
    display: table;
    width: 100%;

    + * {
      margin-top: 16px;
    }
  }

  &__week {
    display: table-row;

    &:not(:first-child) {
      .dispatch-calendar__day:first-child {
        border-left: 1px solid #D8D8D8;
      }

      .dispatch-calendar__day:last-child {
        border-right: 1px solid #D8D8D8;
      }
    }

    &:nth-child(2) {
      .dispatch-calendar__day {
        border-top: 1px solid #D8D8D8;

        &:first-child {
          border-top-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
        }
      }
    }

    &:last-child {
      .dispatch-calendar__day {
        border-bottom: 1px solid #D8D8D8;

        &:first-child {
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  &__day {
    display: table-cell;
    padding: 0;
    width: calc(100% / 7);
    height: 36px;
    vertical-align: middle;
    border: 1px solid #FFF;
    cursor: pointer;
    background-color: #FFF;

    &:hover {
      background-color: #FCF1DA;
      border-color: #FCF1DA;
    }

    &--current {
      border-color: #ED4A3F !important;
    }

    &--selected {
      cursor: initial;
      background: linear-gradient(86.19deg, #ED4A3F 19.16%, #F99335 78.75%);
      border-color: #ED4A3F !important;

      .dispatch-calendar__text {
        color: #FFF !important;
      }
    }

    &--disabled {
      cursor: initial;
      background-color: #F5F6F8 !important;
      border-color: #F5F6F8;

      .dispatch-calendar__text {
        color: #ACACAC !important;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #1C2246;
    margin: 0;
  }
}
