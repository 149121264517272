.b-form {
  height: 100%;

  &--dense {
    .b-form__content {
      padding: 0 0 16px;
    }

    .b-form__footer {
      padding: 16px 0 0;
    }
  }

  &__content {
    padding: 16px;
    height: calc(100% - 73px - 32px);
    overflow-y: auto;
  }

  &__footer {
    border-top: 1px solid #D8D8D8;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
