.recovery-pwd-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1C2246;
  }
  &__form {
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  &__btn {
    width: 100%;
  }
}