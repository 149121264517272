@import "~normalize.css";
@import "fonts";

html, body {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  background-color: #FFF;
}

.no-scroll {
  overflow: hidden !important;
}

button, input, textarea, select, button, label {
  font-family: 'Inter', sans-serif;
}

p {
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0;
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: #1C2246;
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #1C2246;
}

h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1C2246;
  margin: 0;
}

#root {
  min-width: 1200px;
  height: 100%;
}

.b-container {
  width: 1280px;
  margin: 0 auto;
}

/* Мобильная адаптация лэндинга */
@media only screen and (max-width: 576px) {
  #root {
    min-width: inherit;
    box-sizing: border-box;
  }

  .b-container {
    width: initial;
  }
}


*::-webkit-scrollbar {
  height: 6px;
  width: 6px; /* ширина scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent; /* цвет дорожки */
}

*::-webkit-scrollbar-thumb {
  background-color: #B6BCB9; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
}

* {
  scrollbar-width: thin; /* "auto" или "thin"  */
  scrollbar-color: #7A7A7A #FFFFFF; /* плашка скролла и дорожка */
}
