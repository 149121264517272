.b-notification {
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #4F8BFF;

  &--error {
    background-color: #DA616C;
  }

  &--success {
    background-color: #5BCD71;
  }

  &--warning {
    background-color: #FFB84E;
  }

  &__icon-container {
    flex: 0;
    margin-right: 16px;
    color: #FFF;
  }

  &__message {
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
    white-space: pre-wrap;
  }
}
