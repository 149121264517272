.status-step {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 10px;
    padding: 12px 24px;
    border-left: 1px dashed #ACACAC;
    gap: 36px;
    color: #ACACAC;
    &--active {
      color: #1C2246;
      border-left: 1px dashed #ED4A3F;
      .status-step__time {
        color: #636463;
      }
    }
  }
  &__time {
    font-size: 12px;
    line-height: 16px;
  }
  &__title {
    width: 225px;
    overflow: hidden;
  }
}