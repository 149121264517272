.b-list {
  list-style-type: none;
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 0;

  &-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: auto;
    white-space: nowrap;
    padding: 16px;
  }
  &__item {
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #1C2246;
    border-bottom: 1px solid #D8D8D8;
    padding: 12px;
  }
}
