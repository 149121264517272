.dot-custom {
  display: block;

  width: 100%;
  padding: 0;
  margin-bottom: 24px;

  list-style: none;

  text-align: center;
}

.dot-custom li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}

.dot-custom li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.dot-custom li button:hover,
.dot-custom li button:focus {
  outline: none;
}

.dot-custom li button:hover:before,
.dot-custom li button:focus:before {
  opacity: 1;
}

.dot-custom li button:before {
  font-size: 36px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '•';
  text-align: center;

  color: #69748C;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dot-custom li.slick-active button:before {
  color: #ED4A3F;
}
