.working-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 928px;
  align-self: center;
  margin-bottom: 24px;

  border: 1px solid #ececec;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);

  &__title {
    text-align: center;
  }

  &__header-printing {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 24px;
    background: #F5F6F8;
  }
  &__gov-num {
    width: 80px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &__shift {
    width: 32px;
    &:hover {
      transform: scale(1.1);
      transition: transform ease-in-out 0.2s;
    }
  }
  &__title {
    flex: 1;
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: column;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    &--open {
      overflow: initial;
      max-height: 500px;
    }
  }
  &__cols {
    display: flex;
    flex-direction: row;
    //align-items: flex-start;
    padding: 16px;
    gap: 12px;
  }
  &__col {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    //align-items: flex-end;
    padding: 16px;
    gap: 12px;
    background: #FFFFFF;
    border: 0.5px solid #D8D8D8;
    border-radius: 2px;
  }
  &__datetime {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  &__fill-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    gap: 4px;
    height: 24px;
    background: linear-gradient(90deg, #ED4A3F 20%, #F99335 80%);
    border: none;
    border-radius: 100px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.5;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  &__play-btn {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    &--confirmed {
      background-color: #5BCD71;
    }
    &--didnt-work {
      background-color: #ACACAC;
    }
    &--saved {
      // ранее был синий #4F8BFF
      background-color: #DA616C;
    }
    &--consideration {
      background-color: #FFB84E;
    }
    &--expired {
      background-color: #DA616C;
    }

    &:hover {
      opacity: 0.67;
    }
    &:active {
      opacity: 0.5;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  &__buttons-col {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;
  }
  &__comment {
    height: 80px;
  }
  &__bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    padding: 0 16px 16px;

    #inner {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  &__info {
    display: flex;
    &--label {
      max-width: 180px;
      color: #636463;
    }
    &--title {
      flex: 1;
      text-align: right;
      color: #1C2246;
    }
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
