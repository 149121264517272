.b-text-field {
  position: relative;

  &__clear {
    height: 24px;
    width: 24px;
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin-top: 8px;
    margin-right: 8px;
    background: transparent;
    border: none;
    border-radius: 50%;
    opacity: 0.7;
    cursor: pointer;

    &--icon-offset {
      margin-right: 32px;
    }

    &:hover {
      background: #F4F4F4;
      opacity: 1;
    }
  }

  &__input,
  &__textarea {
    border: none;
    outline: none;
    height: inherit;
    flex: 1;
    box-sizing: border-box;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    border-radius: 4px;

    &::placeholder {
      color: #ACACAC;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #ACACAC;
    }

    &::-ms-input-placeholder {
      color: #ACACAC;
    }
  }

  &__input {
    height: 40px;
    padding: 0 38px 0 12px;
    width: 100%;

    &[type="color"] {
      padding-left: 2px;
      background-color: #fff;
    }

    &--disabled {
      padding-right: 12px;
    }
  }

  &__textarea {
    padding: 8px 12px;
    min-height: 80px;
    width: 100%;
  }
}
