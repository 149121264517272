.b-dt-picker {
  position: relative;

  &__trigger {
    cursor: pointer;
    width: 100%;
    height: 40px;
    text-align: left;
    border: none;
    outline: none;
    background-color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    box-sizing: border-box;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      height: 100%;
      border: 0;
      padding: 0;
      box-sizing: border-box;
      outline: none;
      border-radius: 4px;
      overflow: hidden;
      width: 100%;

      &::placeholder {
        color: #ACACAC;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #ACACAC;
      }

      &::-ms-input-placeholder {
        color: #ACACAC;
      }
    }
  }

  &__paper {
    display: none;
    position: absolute;
    left: 0;
    top: 64px;
    background-color: #FFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    overflow-y: auto;
    z-index: 2;
    width: 380px;
    min-height: 352px;
    box-sizing: border-box;
    padding: 16px;


    &--visible {
      display: block;
    }

    &--top {
      top: initial;
      bottom: 44px;
    }

    &--right {
      left: initial;
      right: 0;
    }
  }

  &__append-icon {
    position: absolute;
    width: 24px;
    right: 32px;
    top: 0;
    bottom: 0;
    padding: 8px;
    cursor: pointer;
    border: none;
    background: white;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__calendar-icon {
    margin-top: 4px;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
