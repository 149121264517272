.b-tabs {
  &--transparent {
    .b-tabs {
      &__labels {
        background-color: transparent;
      }

      &__label {
        background-color: transparent;
      }
    }
  }

  &--bordered {
    .b-tabs__labels {
      border-bottom: 1px solid #D8D8D8;
    }
  }

  &__labels {
    display: flex;
    background-color: #FFF;
    box-sizing: border-box;
    padding: 0 16px;
  }

  &__label {
    box-sizing: border-box;
    border: none;
    position: relative;
    background: #FFF;
    outline: none;
    height: 56px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0;

    + .b-tabs__label {
      margin-left: 24px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 4px;
      background: linear-gradient(86.19deg, #ED4A3F 19.16%, #F99335 78.75%);
      border-radius: 4px 4px 0 0;
      position: absolute;
      bottom: 0;
      left: 0;
      display: none;
    }

    &--active {
      &::before {
        display: block;
      }

      .b-tabs__text {
        color: #1C2246;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ACACAC;
  }

  &__badge {
    position: absolute;
    right: -14px;
    top: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background: #DA616C;
    border-radius: 40px;
    font-size: 12px;
    line-height: 16px;
    margin-left: auto;
  }

  &__content {
  }
}
