.b-select {
  position: relative;

  &__trigger {
    cursor: pointer;
    width: 100%;
    height: 40px;
    text-align: left;
    border: none;
    outline: none;
    background-color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    box-sizing: border-box;
    overflow: hidden;

    &:hover {
      input {
        padding: 0 56px 0 12px;
      }
      .b-select__clear-btn {
        visibility: visible;
      }
      &::after {
        opacity: 1;
      }
    }

    &--placeholder {
      color: #ACACAC;
    }

    input {
      height: 100%;
      border: 0;
      padding: 0 40px 0 12px;
      box-sizing: border-box;
      outline: none;
      border-radius: 4px;
      overflow: hidden;
      width: 100%;

      &::placeholder {
        color: #ACACAC;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #ACACAC;
      }

      &::-ms-input-placeholder {
        color: #ACACAC;
      }
    }
  }

  &__paper {
    display: none;
    position: absolute;
    top: 48px;
    width: 100%;
    max-height: 240px;
    background-color: #FFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    overflow-y: auto;
    z-index: 2;

    &--visible {
      display: block;
    }
  }

  &__option {
    padding: 12px 16px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1B1B1B;
    text-align: left;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: block;
    width: 100%;

    &--selected,
    &:hover {
      background: #F5F6F8;
    }
  }

  &__clear-btn {
    height: 24px;
    width: 24px;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 24px;
    padding: 0;
    margin-top: 8px;
    margin-right: 8px;
    background: transparent;
    border: none;
    border-radius: 50%;
    opacity: 0.7;
    cursor: pointer;
    visibility: hidden;

    &:hover {
      background: #F4F4F4;
      opacity: 1;
    }
  }
}
