.toggle-btn {
  flex: 1;
  display: flex;
  //flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  gap: 10px;
  height: 32px;

  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #707781;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;

  &--active {
    color: #FFFFFF;
    background: linear-gradient(90deg, #ED4A3F 45%, #F99335 100%);
    pointer-events: none;
  }
  &--dark {
    background: #1C2246;
  }
}

.container {
  display: flex;
  flex-direction: row;
  padding: 4px;
  gap: 4px;
  height: 40px;
  width: 100%;
  //margin-bottom: 8px;

  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 16px;
}
